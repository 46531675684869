class Wcag {
    constructor() {

        function handleFirstTab(e) {
            if (e.defaultPrevented) {
                return;
            }
        
            let key = e.key || e.keyCode;
        
            if (key === 'Tab' || key === 9) {
                document.body.classList.add("user-is-tabbing");
                window.removeEventListener("keydown", handleFirstTab);
            }
        }

        window.addEventListener("keydown", handleFirstTab);

    }
}


export default Wcag