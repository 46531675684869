class Scrollspy {
    constructor() {

        const isoScrollSpy = (targetSelectorAll = "", toggleClass = "") => {
            const targets = document.querySelectorAll(targetSelectorAll),
                options = {
                    rootMargin: "-50% 0px",
                };
            const inView = (target) => {
                const interSecObs = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                const currentElement = entry.target,
                    currentNav = document.querySelector(`a[href='#${currentElement.id}']`);
                if (entry.isIntersecting) {
                    currentNav.classList.add(toggleClass);
                } else {
                    currentNav.classList.remove(toggleClass);
                }
                });
                }, options);
                interSecObs.observe(target);
            };
            targets.forEach(inView);
        };

        document.addEventListener("DOMContentLoaded", () => {

            isoScrollSpy("section", "active");
        
        });


    }
}


export default Scrollspy