class Navigation {
    constructor() {

        const nav = document.querySelector("header");
        const menuButton = document.getElementById("js-toggle");
        const menuOverlay = document.getElementById("js-overlay");
        const menuLinks = document.querySelectorAll(".nav__item");

        function toggleOpen() {
            
            // Listen for indicators to close the modal
            window.onclick = function (event) {
                if (event.target.id === "js-overlay") {
                    closeMenu();
                }
            };

            Array.from(menuLinks).forEach(function (element) {
                element.addEventListener("click", closeMenu);
            });

            // Show the modal and overlay
            menuButton.classList.toggle("is-activated");
            menuOverlay.classList.toggle("is-open");

        }

        function closeMenu() {
            menuButton.classList.remove("is-activated");
            menuOverlay.classList.remove("is-open");
        }

        menuButton.addEventListener("click", function () {
            if (menuButton.classList.contains("is-activated")) {
                closeMenu();
            } else {
                toggleOpen();
            }
        });

        function minNavbar() {
        
            let topOfNav = nav.offsetTop;
            if (window.scrollY > topOfNav) {
                nav.classList.add("scrolled-down");
            } else {
                nav.classList.remove("scrolled-down");
            }
        }

        window.addEventListener("scroll", minNavbar);
        
    }
}


export default Navigation