import Flickity from "flickity";
import "flickity/dist/flickity.min.css";

class Carousel {
    constructor() {
        this.initCarousels();
    }

    initCarousels() {
        const carousels = document.querySelectorAll('.carousel');
        carousels.forEach(carousel => {
            new Flickity(carousel, {
                wrapAround: true,
                autoPlay: true,
                pageDots: false,
                contain: true,
                cellAlign: 'left'
            });
        });
    }
}

export default Carousel;
